<template>
  <div class="lab-dropdown sm region w-100">
    <div class="lab-dropdown-btn" @click="isDropDown = !isDropDown" ref="dropdownPoplimit">
      <span class="lab-dropdown-btn-value">
        {{ selectedPopTitle }}
      </span>
      <span class="lab-dropdown-btn-icon">
        <DropdownOneIcon />
      </span>
    </div>
    <div class="lab-dropdown-content population-selection cs-scroll-style" :class="{ 'show': isDropDown}">
      <ul>
        <li v-for="item in limitTypes"
            :key="item.id"
            @click="$store.state.periodicalResult.popLimit = item.value">
            {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';

export default {
  components: {
    DropdownOneIcon,
  },
  data() {
    return {
      isDropDown: false,
      limitTypes: [
        { value: 0, name: 'No limit' },
        { value: 3, name: '3 Pop' },
        { value: 4, name: '4 Pop' },
        { value: 5, name: '5 Pop' },
      ],
    } 
  },
  props: {
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownPoplimit;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isDropDown = false;
        }
      } catch (error) {}
    },
  },
  computed:{
    selectedPopTitle() {
      const popNumber = this.$store.state.periodicalResult.popLimit;
      return popNumber === 0 ? 'No limit' : popNumber + ' Pop';
    },
  },
  mounted() {
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>
