<template>
  <div class="default-region-container mb-3">
    <div class="default-region-header mb-1">
      <div class="w-50">
        <h5 class="report-subtitle">Default Region</h5>
      </div>
      <div class="w-50 text-right">
        <a @click="selectRegionModal.showModal = true">
          <span class="change-button">Change</span><SettingsIcon class="settings-icon" />
        </a>
      </div>
    </div>
    <div class="default-region-content">
      <div class="default-region-content-item">
        <b-form-input
          class="region-input"
          id="region"
          :disabled="true"
          :placeholder="$t(selectedRegionGroup.grupName)"
        />
      </div>
      <div class="default-region-content-item">
        <b-form-input
          :disabled="true"
          class="region-input"
          id="subRegion"
          :placeholder="$t(selectedRegion.title)"
          v-if="!isGlobal"
        />
        <PeriodicalPopLimit v-else />
      </div>
      
    </div>

    <SelectRegionModal v-if="selectRegionModal.showModal" :modalObject="selectRegionModal"/>
  </div>
  
</template>

<script>
import SettingsIcon from '@/layouts/components/icons/SettingsIcon.vue';
import SelectRegionModal from '../modals/SelectRegionModal.vue';
import PeriodicalPopLimit from './PeriodicalPopLimit.vue';

export default {
  components: {
    SettingsIcon,
    SelectRegionModal,
    PeriodicalPopLimit,
  },
  data() {
    return {
      selectRegionModal: {
        showModal: false,
      },
    } 
  },
  props: {
  },
  computed: {
    selectedRegionGroup() {
      return this.$store.state.region.selectedRegionGroup;
    },
    selectedRegion() {
      return this.$store.state.region.selectedRegion;
    },
    isGlobal() {
      const guid = this.selectedRegion.guid;
      if (guid === '81b2bed1-f2ac-4bc6-b597-7ed87fa4cd26' || guid === '438e98b2-4ce9-4cc1-9155-f37e910eb2d4') {
        return true;
      }
      return false;
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
  .default-region-container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .default-region-header {
    display: flex;
    width: 100%;
  }

  .default-region-content {
    display: flex;
    width: 100%;
  }
  .default-region-content-item {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .region-input {
    width: 95%;
  }
  .change-button {
    font-size: 0.8em;
    cursor: pointer;
  }
  .settings-icon {
      width: 1.2em;
      margin-left: 5px;
  }
@media screen and (max-width: 1079px) {
  body {
    background-color: blue;
  }
  .default-region-content {
    flex-direction: column;
  }
  .default-region-content-item {
    width: 100%;
    margin-bottom:10px;

    .region-input {
      width: 100%;
    }
  }
}

</style>
